import { UNION_ID } from '~/etc/env'

import { http } from 'vue-resource'

http.interceptors.push(
  (request, next) => {
    request.url = request.url.replace(/(?<=^\/api)\/device(?=\/)/, '/device_app')

    request.params.unionid = UNION_ID

    next(response => response)
  }
)
